import BannerBG from "@components/Banner/BannerBG"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import Layout from "@components/Layout/Layout"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import GGFXImage from "@components/elements/GGFXImage"
// import BookaViewingForm from "@components/forms/BookaViewingForm"
import loadable from "@loadable/component"
// import EnquiryForm from "@components/forms/EnquiryForm"
// import ListYourPropertyForm from "@components/forms/ListYourPropertyForm"
// import SendCVForm from "@components/forms/SendCVForm"
import Seo from "@components/seo"
import { parseHTML } from "@lib/utils"
import "@styles/team-detail.scss"
import clsx from "clsx"
import { graphql, navigate } from "gatsby"
import { isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { ENQUIRY_FORMS } from "@components/forms/utils"
import { PageLinks } from "@common/site/page-static-links"
import CPForm from "@components/forms/CPForm"
import useBrandInfo from "@hooks/dataHooks/useBrandInfo"
import TextCopy from "@components/TextCopy/TextCopy"
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))

const BookaViewingForm = loadable(() =>
  import("@components/forms/BookaViewingForm")
)
const EnquiryForm = loadable(() => import("@components/forms/EnquiryForm"))
const ListYourPropertyForm = loadable(() =>
  import("@components/forms/ListYourPropertyForm")
)
const SendCVForm = loadable(() => import("@components/forms/SendCVForm"))

const FormTemplate = ({ data, location }) => {
  const pageData = data?.strapiPage
  const menuData = pageData?.menu
  const modules = pageData?.modules
  // const imagetransforms = pageData?.ggfx_results
  const strapiID = pageData?.strapi_id
  const locationState = location?.state
  const property = locationState?.property
  const propertyURL = locationState?.propertyURL
  const emailloc = locationState?.email
  const crm_negotiator_id= locationState?.crm_negotiator_id
  const name = locationState?.name
  const jobPosition = locationState?.jobPosition

  const module = modules?.[0]
  const module1 = modules?.[1]
  let prev_state = location?.state;
  if (!module) {
    navigate("/404/")
  }
  else if(module?.choose_form === "Book_a_Viewing" && prev_state === null){
    navigate(`/buy/properties-for-sale/`)
  }
  
  const { title, description, bg_image, choose_form } = module

  const { phone, address, london_phone, email, cp_email } = useBrandInfo()

  return (
    <Layout pageData={pageData}>
            {pageData?.video_ask_url &&
<VideoAskWidget video_ask_url={pageData?.video_ask_url} />
}
      <div className={clsx("contact-module-wrap", bg_image && "blue-bg")}>
        {bg_image ? (
          <div className="bg-section">
            <GGFXImage
              ImageSrc={bg_image}
              altText="banner-bg"
              imagetransforms={pageData?.ggfx_results}
              renderer="srcSet"
              imagename="page.banner_image.banner_img"
              strapiID={strapiID}
            />
            <div className="overlay" />
          </div>
        ) : (
          <BannerBG />
        )}
        <Breadcrumbs menuData={menuData} whiteColor={bg_image} backButton={choose_form !== ENQUIRY_FORMS.ComplaintsProcedure} />
        <Container className="contact-module-container">
          <div className="content">
            <h1 className="title">{title}</h1>
            <div className="description">
              {parseHTML(description.data.description)}
            </div>
          </div>
          <div className="module-sections">
            <div className="left-section">
              {(choose_form === ENQUIRY_FORMS.generalEnquiry ||
                choose_form === ENQUIRY_FORMS.partnerProgram ||
                choose_form === ENQUIRY_FORMS.propertyMangement ||
                choose_form === ENQUIRY_FORMS.mortagage ||
                choose_form === ENQUIRY_FORMS.snagging ||
                choose_form === ENQUIRY_FORMS.prism ||
                choose_form === ENQUIRY_FORMS.conveyancing ||
                choose_form === ENQUIRY_FORMS.propertyLeasing ||
                choose_form === ENQUIRY_FORMS.primestay) && (
                <EnquiryForm choose_form={choose_form} />
              )}
              {choose_form === "Book_a_Viewing" && (
                <BookaViewingForm propertyURL={propertyURL} crm_negotiator_id={crm_negotiator_id} email={emailloc} property={property} name={name} />
              )}
              {choose_form === "Book_a_Valuation" && (
                <ListYourPropertyForm noRow />
              )}
              {choose_form === ENQUIRY_FORMS.ComplaintsProcedure && (
                <CPForm />
              )

              }
              {choose_form === "Send_us_your_CV" && (
                <SendCVForm jobPosition={jobPosition} />
              )}
            </div>
            <div className="right-section">
            {choose_form === ENQUIRY_FORMS.ComplaintsProcedure && (
              <div className="address-about">
                <h2>contact details</h2>
                <h4>Office Address</h4>
                <div className="address-content">
                {parseHTML(address?.data?.address)}
                </div>
                <div className="divi"></div>
                <h4>Dubai Phone</h4>
                <a href={`tel:${phone}`} className="phone">{phone}</a>
                <div className="divi"></div>
                {london_phone &&<>
                <h4>London Phone</h4>
                <a href={`tel:${london_phone}`} className="phone">{london_phone}</a>
                <div className="divi"></div></>}
                <h4>Email</h4>
                <a href={`mailto:${cp_email}`} className="email">{cp_email}</a>
              </div>
            )}
              {!isEmpty(property) && choose_form === "Book_a_Viewing" && (
                <PropertyCard
                isbookaView
                bitrix_id={property?.extra?.bitrix_id}
                images={property?.images}
                email={property?.crm_negotiator_id?.email}
                name={property?.crm_negotiator_id?.name}
                crm_negotiator_id={property?.crm_negotiator_id?.id}
                singnatureProp={property?.search_type === "sales" && property?.price >= 20000000 && property?.department === "residential" ? true : false}
                link_to_employee={property?.link_to_employee}
                  {...{ ...property, listView: false, noContact: true }}
                />
              )}
            </div>
          </div>
        </Container>
        {module1?.strapi_component === "components.text-rich-text" && (
            <TextCopy
              content={module1?.description?.data?.description}
              title={module1?.title}
            />
          )}
      </div>
    </Layout>
  )
}

export default FormTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TEXT_RICH_TEXT {
          ...textRichText
        }
        ... on STRAPI__COMPONENT_MODULES_FORMS {
          id
          title
          strapi_id
          strapi_component
          choose_form
          description {
            data {
              description
            }
          }
          bg_image {
            url
          }
        }
      }
    }
  }
`
